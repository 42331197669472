import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/autoplay";
import "swiper/css";
import Product1 from "../../assets/Image/thumbnail QRA web.png";
import Product2 from "../../assets/Image/thumbnail QRH web.png";
import Product3 from "../../assets/Image/thumbnail QRM web.png";
import Woodcircle from "../../assets/Image/wood button 2.png";

import { Autoplay } from "swiper/modules";
const Content = () => {
  return (
    <div className='h-full w-screen sm:w-[70vw] sm:rounded-[50px] sm:bg-white shadowbox1 sm:mx-[15vw] sm:pb-20 mb-10'>
      <div className='hidden sm:flex h-28 w-full  flex-row justify-between items-center sm:p-5'>
        <img src={Woodcircle} className='w-20' alt='' />
        <h1 className='text-4xl lora'>
          <span className='font-bold'>Produk</span> Kami
        </h1>
        <img src={Woodcircle} className='w-20' alt='' />
      </div>
      <Swiper
        className='w-screen sm:w-[60vw] sm:bg-gray-200 rounded-xl sm:border-2 sm:border-[#cdcdcd] relative '
        loop={true}
        modules={[Autoplay]}
        speed={6000}
        freeMode={false}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        slidesPerView={2}
        spaceBetween={80}
        breakpoints={{
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}>
        <SwiperSlide className='w-20'>
          <a href='https://www.tokopedia.com/qro-official/q-uro-anggur-hijau-650ml?utm_campaign=ManageProduct-13731249-13098767140-0-240424-default&utm_source=salinlink&utm_medium=share'>
            <div className='m-10 p-4  bg-white w-48 h-72 rounded-xl shadowbox2 flex flex-col items-center border-[1px] '>
              <img src={Product1} alt='' />
              <div className='w-full text-left'>
                <h1 className='text-lg py-2'>
                  <span className='font-bold lora '>Q'RO</span> Avici
                </h1>
                <p className='text-sm publicsans font-thin italic'>650ml</p>
                <p className='text-sm publicsans font-thin italic'>
                  Alcohol +/- 19.80%
                </p>
              </div>
            </div>
          </a>
        </SwiperSlide>
        <SwiperSlide className='w-20'>
          <a href='https://www.tokopedia.com/qro-official/q-uro-anggur-hijau-650ml?utm_campaign=ManageProduct-13731249-13098767140-0-240424-default&utm_source=salinlink&utm_medium=share'>
            <div className='m-10 p-4  bg-white w-48 h-72 rounded-xl shadowbox2 flex flex-col items-center border-[1px] '>
              <img src={Product2} alt='' />
              <div className='w-full text-left'>
                <h1 className='text-lg py-2'>
                  <span className='font-bold lora '>Q'RO</span> Anggur Hijau
                </h1>
                <p className='text-sm publicsans font-thin italic'>650ml</p>
                <p className='text-sm publicsans font-thin italic'>
                  Alcohol +/- 19.80%
                </p>
              </div>
            </div>
          </a>
        </SwiperSlide>
        <SwiperSlide className='w-20'>
          <a href='https://www.tokopedia.com/qro-official/q-uro-anggur-hijau-650ml?utm_campaign=ManageProduct-13731249-13098767140-0-240424-default&utm_source=salinlink&utm_medium=share'>
            <div className='m-10 p-4  bg-white w-48 h-72 rounded-xl shadowbox2 flex flex-col items-center border-[1px] '>
              <img src={Product3} alt='' />
              <div className='w-full text-left'>
                <h1 className='text-lg py-2 whitespace-nowrap'>
                  <span className='font-bold lora whitespace-nowrap'>Q'RO</span> Anggur Merah
                </h1>
                <p className='text-sm publicsans font-thin italic'>650ml</p>
                <p className='text-sm publicsans font-thin italic'>
                  Alcohol +/- 19.80%
                </p>
              </div>
            </div>
          </a>
        </SwiperSlide>
        <SwiperSlide className='w-20 hidden sm:block'>
          <a href='https://www.tokopedia.com/qro-official/q-uro-anggur-hijau-650ml?utm_campaign=ManageProduct-13731249-13098767140-0-240424-default&utm_source=salinlink&utm_medium=share'>
            <div className='m-10 p-4  bg-white w-48 h-72 rounded-xl shadowbox2 flex flex-col items-center border-[1px] '>
              <img src={Product1} alt='' />
              <div className='w-full text-left'>
                <h1 className='text-lg py-2 whitespace-nowrap'>
                  <span className='font-bold lora whitespace-nowrap'>Q'RO</span> Avici
                </h1>
                <p className='text-sm publicsans font-thin italic'>650ml</p>
                <p className='text-sm publicsans font-thin italic'>
                  Alcohol +/- 19.80%
                </p>
              </div>
            </div>
          </a>
        </SwiperSlide>
        <SwiperSlide className='w-20 hidden sm:block'>
          <a href='https://www.tokopedia.com/qro-official/q-uro-anggur-hijau-650ml?utm_campaign=ManageProduct-13731249-13098767140-0-240424-default&utm_source=salinlink&utm_medium=share'>
            <div className='m-10 p-4  bg-white w-48 h-72 rounded-xl shadowbox2 flex flex-col items-center border-[1px] '>
              <img src={Product2} alt='' />
              <div className='w-full text-left'>
                <h1 className='text-lg py-2'>
                  <span className='font-bold lora '>Q'RO</span> Anggur Hijau
                </h1>
                <p className='text-sm publicsans font-thin italic'>650ml</p>
                <p className='text-sm publicsans font-thin italic'>
                  Alcohol +/- 19.80%
                </p>
              </div>
            </div>
          </a>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Content;
