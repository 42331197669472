import BotolQro from "../../assets/Image/hijau.png";
import BotolQroRight from "../../assets/Image/merah.png";
import BotolQroLeft from "../../assets/Image/hijau.png";
import BotolQroMid from "../../assets/Image/avici.png";
import {
  useMotionValueEvent,
  motion,
  useScroll,
  useTransform,
} from "framer-motion";

const Botols = () => {
  let { scrollYProgress } = useScroll();
  let y = useTransform(scrollYProgress, [0, 0.2], ["0px", "150px"]);
  let x = useTransform(scrollYProgress, [0, 1], ["0px", "-150px"]);
  let rotate = useTransform(scrollYProgress, [0, 1], ["0deg", "360deg"]);

  return (
    <div className='w-full h-full absolute '>
      <img
        src={BotolQroLeft}
        className='ml-2 sm:ml-[16vw] mt-[25vh] sm:mt-[10vh] absolute left-0 w-40 sm:w-[10vw] -rotate-12'
        alt=''
      />
      <img
        src={BotolQroMid}
        className='z-0 mt-[25vh] sm:mt-[6vh] absolute left-1/2 transform -translate-x-1/2 w-40 sm:w-[10vw] rotate-0'
        alt=''
      />
      <img
        src={BotolQroRight}
        className='z-0 ml-2 sm:mr-[16vw] mt-[25vh] sm:mt-[10vh] absolute right-0 w-40  sm:w-[10vw] rotate-12'
        alt=''
      />
    </div>
  );
};

export default Botols;
