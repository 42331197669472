import React from "react";
import Bgimage from "../../assets/Image/wine field circle background.png";
import Herotitle from "../../assets/Image/Group 1.png";
import Botols from "./Botols";
import Wings from "./Wings";
import Titlecompare from "../../assets/Image/Group 2.png";

import {
  useMotionValueEvent,
  motion,
  useScroll,
  useTransform,
} from "framer-motion";

const Home = () => {
  let { scrollYProgress } = useScroll();
  let y = useTransform(scrollYProgress, [0, 1], ["0px", "-150px"]);
  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    console.log("Page scroll: ", latest);
  });
  return (
    <motion.div id="home" className=' h-[120vh] w-full justify-center items-center flex overflow-hidden'>
      <motion.div className='absolute top-0 '>
        <img
          src={Bgimage}
          className='min-h-[110vh] -z-30 sm:h-[150vh]  sm:min-w-screen w-screen overflow-hidden object-cover'
          alt=''
          style={{ y }}
        />
        <img
        src={Titlecompare}
        style={{ y }}

        className='w-full sm:w-[44vw] px-5 sm:px-0 object-contain absolute -bottom-9 sm:-bottom-14  left-1/2 transform -translate-x-1/2 z-30
        '
        alt=''
      />
      </motion.div>

      {/* <motion.div style={{ y }} className="h-full">
        <img className='relative z-50 h-28 sm:h-[10vw] mt-[10vh] sm:mb-48' src={Herotitle} alt='' />
      </motion.div> */}

      <Wings />
      <Botols />

      
    </motion.div>
  );
};

export default Home;
